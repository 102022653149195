* {
    box-sizing: border-box;
}

:root {
    --background-color: #efebe3;
    --card-color: #ffffff;
    background: var(--background-color);
    font-family: sans-serif;
}

html,body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

blockquote[cite]::after {
    content: " (" attr(cite) ")";
    font-style: italic;
}

ul {
    margin: 0;
    padding: 0 0 0 1rem;
}

li {
    margin-bottom: .5rem;
}

main {
    flex: 1;
    width: 100%;
    max-width: 800px;
    margin: 1rem auto;
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}

header figure {
    margin: 0;
    padding-right: 1rem;
}

header figure img {
    border-radius: 100%;
}

section.two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

section.three-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.card {
    background: var(--card-color);
    box-shadow: 1px 1px 4px #000;
    padding: 1rem;
    margin-bottom: 1rem;
}

body > footer {
    background: var(--card-color);
    font-size: .8rem;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    box-shadow: 1px 1px 10px #000;
    padding: 1rem;
    flex-shrink: 0;
}

body > footer > * {
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
}

body > footer > p {
    text-align: right;
}

body > footer ul {
    margin: 0;
    padding: 0;
}

body > footer li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
