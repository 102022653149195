* {
  box-sizing: border-box;
}

:root {
  --background-color: #efebe3;
  --card-color: #fff;
  background: var(--background-color);
  font-family: sans-serif;
}

html, body {
  height: 100%;
}

body {
  flex-direction: column;
  padding-top: 1rem;
  display: flex;
}

blockquote[cite]:after {
  content: " (" attr(cite) ")";
  font-style: italic;
}

ul {
  margin: 0;
  padding: 0 0 0 1rem;
}

li {
  margin-bottom: .5rem;
}

main {
  flex: 1;
  width: 100%;
  max-width: 800px;
  margin: 1rem auto;
}

header {
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

header figure {
  margin: 0;
  padding-right: 1rem;
}

header figure img {
  border-radius: 100%;
}

section.two-col {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
}

section.three-col {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  display: grid;
}

.card {
  background: var(--card-color);
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 4px #000;
}

body > footer {
  background: var(--card-color);
  flex-flow: wrap;
  flex-shrink: 0;
  align-items: stretch;
  padding: 1rem;
  font-size: .8rem;
  display: flex;
  box-shadow: 1px 1px 10px #000;
}

body > footer > * {
  width: 50%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

body > footer > p {
  text-align: right;
}

body > footer ul {
  margin: 0;
  padding: 0;
}

body > footer li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*# sourceMappingURL=index.fc915d8e.css.map */
